import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import UiCore from '../Components/UiCore';
import NumericTextField from '../Components/NumericTextField';
import ExpensePageViewDialog from '../Components/ExpensePageViewDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

import AlertIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import { GlobalContext } from '../Context/Global.context';
import { CurrencyWithSeparators } from '../Util/NumberFormatting';
import { IsMobile } from '../Util/MobileDetector';

import {
  GetEmployeeControl,
} from '../Util/Employee';
import {
  GetNewExpense,
  GetSubTotal,
  GetTotal,
} from '../Util/Expense';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  contentGrid: {
  },
  lineItem: {
    "&:hover $deleteRowIconButton": {
      display:"initial",
    }
  },
  deleteRowIconButton: {
    display:(!IsMobile()) ? "none" : undefined,
  },
  overlay: {
    position: "absolute",
    fontSize:(IsMobile()) ? 48 : 80,
    fontWeight:700,
    transform: "rotate(20deg)",
    right:50,
    top:200,
    opacity:0.4,
  },
  scrollableGridItem: {
    overflowY:"auto",
    height:"100%",
    paddingTop:"0 !important",
  },
  pageViewDrawerPaper: {
    width:"95%",
  },
});

class ExpenseViewer extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.initialState = {
      ShowExpensePageViewer: false,
      PageViewerStartingPageID: 0,
      ShowProgressIndicatorImmediately: false,
      ShowPageProcessingIndicator: false,
    }

    this.state = {
      ...this.initialState,
      Expense: null,
      IsReady: false,
      ShowProgressIndicatorImmediately: true,
    }

    this.TotalAlertRef = React.createRef();
    this.ExpenseDataGridRef = React.createRef();
    this.PageViewGridRef = React.createRef();
    this.ScreenContentRef = null;
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  componentDidMount() {
    this.context.GetUser(true)
      .then(user => {
        this.setState({
          ShowProgressIndicatorImmediately: false,
        });

        if (this.props.onLoadSingleExpense) {
          this.handleLoadSingleExpense();
        } else {
          this.setState({
            Expense: GetNewExpense(),
            IsReady: true,
          });
        }
      })
      .catch(this.handleApiError);
  }

  componentDidUpdate() {
  }

  handleSetScreenContentRef = ref => {
    this.ScreenContentRef = ref;
  }

  handleReset = () => {
    this.setState({ ...this.initialState });
  }

  isReportedRecognizedTotalAmountDifferentThanTotal = expense => {
    return expense
      && expense.Expense.RecognizedTotalAmount
      && GetTotal(expense.Expense)
      && expense.Expense.RecognizedTotalAmount.toFixed(2) !== GetTotal(expense.Expense).toFixed(2);
  }

  getTotalAlertText = (expense, short) => {
    const shortLine = `AI's recognized Total is ${CurrencyWithSeparators(expense && expense.Expense.RecognizedTotalAmount, 2)}.`;
    if (short) {
      return shortLine;
    }
    return `${shortLine} Listed items do not match this value.`;
  }

  handleAutoRefresh = expenseInfo => {
    if (!this.state.Expense) {
      this.handleLoadSingleExpense(true);
    }
  }

  handleLoadSingleExpense = (isAutoRefresh, employeeId) => {
    if (!this.props.onLoadSingleExpense) {
      return;
    }

    const scrollToTopFunc = () => {
      if (this.ExpenseDataGridRef && this.ExpenseDataGridRef.current !== null) {
        setTimeout(() => this.ExpenseDataGridRef.scrollTop = 0, 50);
      }
      if (this.ScreenContentRef && this.ScreenContentRef.current !== null) {
        setTimeout(() => this.ScreenContentRef.scrollTop = 0, 50);
      }
      if (this.PageViewGridRef && this.PageViewGridRef.current !== null) {
        setTimeout(() => this.PageViewGridRef.scrollTop = 0, 50);
      }
    };

    if (!isAutoRefresh) {
      this.setState({
        ShowPageProcessingIndicator: false,
        ShowProgressIndicatorImmediately: true,
      });
    }

    this.props.onLoadSingleExpense(employeeId)
      .then(resp => {
        if (resp.data) {
          const expenseList = resp.data;
          if (expenseList.Expenses && expenseList.Expenses.length) {
            const Expense = expenseList.Expenses[0];
            this.setState({ Expense });
            if (this.isReportedRecognizedTotalAmountDifferentThanTotal(Expense)) {
              this.handleAlert({
                BodyText: this.getTotalAlertText(Expense),
                CloseCallback: scrollToTopFunc,
              });
            } else {
              scrollToTopFunc();
            }
          } else {
            let stateToUpdate = {
              Expense: null,
            };
            if (expenseList.HasItemsProcessing) {
              stateToUpdate.ShowPageProcessingIndicator = true;
            }
            this.setState(stateToUpdate);
          }
        }
      })
      .catch(err => {
        if (!isAutoRefresh) {
          this.handleApiError(err);
        }
      })
      .finally(() => {
        this.setState({
          IsReady: true,
          ShowProgressIndicatorImmediately: false,
        });
      });
  }

  handleSubmitExpense = handlerFunc => {
    this.setState({ShowProgressIndicatorImmediately: true});

    handlerFunc({...this.state.Expense})
      .then(resp => {
        this.handleReset();
        this.handleLoadSingleExpense();
        this.context.GetExpenseInfo();
      })
      .catch(this.handleApiError);
  }

  handleUpdateExpenseLineItem = (index, propName, dataType) => e => {
    const Expense = {...this.state.Expense};
    const lineItem = Expense.Expense.LineItems[index];
    if (lineItem) {
      const valueAsString = (e && e.target) ? e.target.value : "";
      switch (dataType) {
      case "float":
        const f = parseFloat(valueAsString);
        if (!isNaN(f)) {
          lineItem[propName] = f;
        } else {
          lineItem[propName] = valueAsString;
        }
        break;
      default:
        lineItem[propName] = valueAsString;
        break;
      }
      this.setState({Expense});
    }
  }

  handleUpdateExpenseProperty = (propName, dataType) => e => {
    const Expense = {...this.state.Expense};
    const valueAsString = (e && e.target) ? e.target.value : "";
      switch (dataType) {
      case "float":
        const f = parseFloat(valueAsString);
        if (!isNaN(f)) {
          Expense.Expense[propName] = f;
        } else {
          Expense.Expense[propName] = valueAsString;
        }
        break;
      default:
        Expense.Expense[propName] = valueAsString;
        break;
      }
    this.setState({Expense});
  }

  handleAddRow = () => {
    const Expense = {...this.state.Expense};
    if (!Expense.Expense.LineItems) {
      Expense.Expense.LineItems = [];
    }
    Expense.Expense.LineItems.push({Description:"",LineAmount:NaN,});
    this.setState({Expense});
  }

  handleDeleteRow = index => {
    const Expense = {...this.state.Expense};
    Expense.Expense.LineItems = Expense.Expense.LineItems.filter((li, i) => i !== index);
    this.setState({Expense});
  }

  handleSetShowExpensePageViewer = ShowExpensePageViewer => {
    this.setState({ShowExpensePageViewer});
  }

  handlePageViewerPageIDChanged = PageViewerStartingPageID => {
    this.setState({PageViewerStartingPageID});
  }

  render() {
    const {
      IsReady,
      Expense,
      ShowExpensePageViewer,
      ApiError,
      Alert,
      ShowProgressIndicatorImmediately,
      ShowPageProcessingIndicator,
      PageViewerStartingPageID,
    } = this.state;
    const {
      classes,
      theme,
      dialogTitle,
      onSubmitExpense,
      onDeleteExpense,
      onApproveExpense,
      onDenyExpense,
      readOnly,
      additionalButtons,
      returnContentOnly,
      allowEmployeeChange,
    } = this.props;
    const {
      User,
    } = this.context;

    const showPagesInline = !IsMobile() && Expense && Expense.PageCount > 0;

    const headerGridItem = (Expense && Expense.Expense.LineItems) ? (
      <Grid item xs={(readOnly) ? 12 : 11} style={{marginTop:theme.spacing(2)}}>
        <Grid container spacing={1}>
          <Grid item xs={(IsMobile()) ? 7 : 9} style={{paddingLeft:17}}>
            Description
          </Grid>
          <Grid item xs={(IsMobile()) ? 5 : 3} style={{paddingLeft:17}}>
            Amount
          </Grid>
        </Grid>
      </Grid>
    ) : null;
    let gridItems = [
      <Grid item key={`li_header`}>
        <Grid container spacing={1}>
          {headerGridItem}
          {(!readOnly) ? ( <Grid item xs={1}></Grid> ) : null}
        </Grid>
      </Grid>
    ];
    const leftColXs = (IsMobile()) ? (readOnly) ? 8 : 7 : 9;
    const rightColXs = (IsMobile()) ? (readOnly) ? 4 : 5 : 3;
    const getInputBackground = isDisabled => (isDisabled) ? undefined : "#eee";
    if (Expense && Expense.Expense.LineItems) {
      Expense.Expense.LineItems.forEach((li, i) => {
        const lineItemGrid = (
          <Grid container spacing={1}>
            <Grid item xs={leftColXs}>
              <TextField
                // label="Description"
                disabled={readOnly}
                fullWidth
                multiline
                style={{ backgroundColor:getInputBackground(readOnly) }}
                variant="outlined"
                onChange={this.handleUpdateExpenseLineItem(i, "Description")}
                value={li.Description}
              />
            </Grid>
            <Grid item xs={rightColXs}>
              <NumericTextField
                id={`amt_${i}`}
                // label="Amount"
                disabled={readOnly}
                isCurrency
                allowNegative
                style={{ backgroundColor:getInputBackground(readOnly) }}
                hideClearButton
                decimalPlaces={2}
                value={li.LineAmount}
                onValueChange={this.handleUpdateExpenseLineItem(i, "LineAmount", "float")}
              />
            </Grid>
          </Grid>
        );
        const deleteRowIconButton = (!readOnly && Expense.Expense.LineItems.length > 1) ? (
          <Tooltip title="Delete row">
            <IconButton
              tabIndex={-1}
              style={{marginTop:(IsMobile()) ? 12 : 4}}
              size={(IsMobile()) ? "small" : undefined}
              className={classes.deleteRowIconButton}
              onClick={() => this.handleDeleteRow(i)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null;
        gridItems.push(
          <Grid item key={`li_${i}`} className={classes.lineItem}>
            <Grid container spacing={1}>
              <Grid item xs={(readOnly) ? 12 : 11}>
                {lineItemGrid}
              </Grid>  
              {(!readOnly) ? (
                <Grid item xs={1} style={{textAlign:"center"}}>
                  {deleteRowIconButton}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        );
      });
    }
    
    const addRowGridItem = (!readOnly) ? (
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={(readOnly) ? 12 : 11}>
            <Grid container spacing={1}>
              <Grid item xs={leftColXs}>
                <Tooltip title="Add row">
                  <IconButton
                    onClick={() => this.handleAddRow()}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>  
              <Grid item xs={rightColXs}>
                <NumericTextField
                  id={`subtotal`}
                  label="SubTotal"
                  isCurrency
                  allowNegative
                  disabled
                  hideClearButton
                  decimalPlaces={2}
                  style={{ backgroundColor:getInputBackground(true) }}
                  value={GetSubTotal(Expense && Expense.Expense)}
                  onValueChange={() => {}}
                />
              </Grid>
            </Grid>
          </Grid>
          {(!readOnly) ? ( <Grid item xs={1}></Grid> ) : null}
        </Grid>
      </Grid>
    ) : null;

    const total = GetTotal(Expense && Expense.Expense);
    const totalAlertText = this.getTotalAlertText(Expense, true);
    const totalAlertIconButton = (Expense && this.isReportedRecognizedTotalAmountDifferentThanTotal(Expense)) ? (
      <Tooltip title={totalAlertText}
        enterTouchDelay={(IsMobile()) ? 0 : undefined}
        leaveTouchDelay={(IsMobile()) ? 3000 : undefined}
      >
        <IconButton
          ref={instance => this.TotalAlertRef = instance}
          size={(IsMobile()) ? "small" : undefined}
          style={{color:red[500]}}
          // onClick={() => {}}
        >
          <AlertIcon />
        </IconButton>
      </Tooltip>
    ) : null;


    const dataGrid = (Expense && gridItems && gridItems.length)
      ? ( 
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container>
              <Grid item xs={(readOnly) ? 12 : 11}>
                <TextField
                  label="Vendor"
                  fullWidth
                  variant="outlined"
                  style={{ backgroundColor:getInputBackground(readOnly) }}
                  disabled={readOnly}
                  onChange={this.handleUpdateExpenseProperty("VendorName")}
                  value={Expense && Expense.Expense.VendorName}
                />
              </Grid>
              {(!readOnly) ? (
                <Grid item xs={1}></Grid>
              ) : null}
            </Grid>
          </Grid>
          {gridItems}
          {addRowGridItem}
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={(readOnly) ? 12 : 11}>
                <Grid container spacing={1} style={{alignItems:"center"}}>
                  <Grid item xs={leftColXs}></Grid>  
                  <Grid item xs={rightColXs}>
                    <NumericTextField
                      id={`tax`}
                      label="Tax"
                      isCurrency
                      allowNegative
                      disabled={readOnly}
                      hideClearButton
                      decimalPlaces={2}
                      style={{ backgroundColor:getInputBackground(readOnly) }}
                      value={Expense.Expense.TaxTotal}
                      onValueChange={this.handleUpdateExpenseProperty("TaxTotal", "float")}
                    />
                  </Grid>
                  <Grid item xs={leftColXs} style={{textAlign:"right"}}>
                    {totalAlertIconButton}
                  </Grid>
                  <Grid item xs={rightColXs}>
                    <NumericTextField
                      id={`total`}
                      label="Total"
                      isCurrency
                      allowNegative
                      disabled
                      hideClearButton
                      decimalPlaces={2}
                      style={{ backgroundColor:getInputBackground(true) }}
                      value={total}
                      onValueChange={() => {}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:theme.spacing(1)}}>
                    <TextField
                      label="Note"
                      fullWidth
                      disabled={readOnly}
                      multiline
                      style={{ backgroundColor:getInputBackground(readOnly) }}
                      variant="outlined"
                      onChange={this.handleUpdateExpenseProperty("PrivateNote")}
                      value={Expense.Expense.PrivateNote}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {(!readOnly) ? (
                <Grid item xs={1}></Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null;

    const showPageViewDialogContent = (!showPagesInline && Expense && Expense.PageCount) ? (
      <Tooltip title="View related pages">
        <IconButton
          onClick={() => this.handleSetShowExpensePageViewer(true)}
        >
          <PhotoLibraryIcon style={{fontSize:32}} />
        </IconButton>
      </Tooltip> 
    ) : null;
    
    const pageViewDialog = (
      <ExpensePageViewDialog
        expense={Expense}
        open={ShowExpensePageViewer}
        onApiError={this.handleApiError}
        onClose={() => this.handleSetShowExpensePageViewer(false)}
        onPageIDChanged={this.handlePageViewerPageIDChanged}
        startingPageID={PageViewerStartingPageID}
        returnContentOnly={IsMobile() || showPagesInline}
      />
    );

    const pageViewer = (IsMobile())
      ? (
      <SwipeableDrawer
        anchor="right"
        classes={{ paper: classes.pageViewDrawerPaper }}
        open={ShowExpensePageViewer}
        onClose={() => this.handleSetShowExpensePageViewer(false)}
        onOpen={() => this.handleSetShowExpensePageViewer(true)}
        style={{position:"relative"}}
      >
        {pageViewDialog}
      </SwipeableDrawer>
    ) : pageViewDialog;

    let overlay;
    if (Expense) {
      const weight = 200;
      let overlayTitle = "";
      let overlayColor = grey[300];
      switch (Expense.Stage) {
      case "Rejected":
        overlayTitle = "Rejected";
        overlayColor = red[weight];
        break;
      case "Complete":
        if (Expense.ApprovalResult) {
          switch (Expense.ApprovalResult) {
          case "Approved":
            overlayTitle = "Approved";
            overlayColor = green[weight];
            break;
          case "Denied":
            overlayTitle = "Denied";
            overlayColor = red[weight];
            break;
          default:
            break;
          }
        } else if (User && User.IsDemoCompany) {
          overlayTitle = "Complete";
        } else {
          overlayTitle = "Submitted";
        }
        break;
      case "Denied":
        overlayTitle = "Denied";
        overlayColor = red[weight];
        break;
      case "Deleted":
        overlayTitle = "Deleted";
        break;
      case "Creation":
        overlayTitle = "Submitting";
        break;
      default:
        break;
      }

      overlay = (
        <div className={classes.overlay} style={{color:overlayColor}}>
          {overlayTitle}
        </div>
      );
    }

    // const employeeDisplayName = (readOnly && Expense && Expense.EmployeeDisplayName) ? (
    //   <TextField
    //     label="Employee"
    //     disabled
    //     variant="outlined"
    //     fullWidth
    //     value={Expense.EmployeeDisplayName}
    //   />
    // ) : null;

    let reviewButtonsGridItem;
    if (onSubmitExpense || onDeleteExpense) {
      const submitButtonGridItem = (onSubmitExpense) ? (
        <Grid item>
          <Button
            variant="contained"
            disabled={!total}
            style={{
              backgroundColor:(total) ? green[500] : undefined,
              color:(total) ? "white" : undefined,
            }}
            onClick={() => this.handleSubmitExpense(onSubmitExpense)}
          >
            Submit
          </Button>
        </Grid>
      ) : null;

      const deleteButtonGridItem = (onDeleteExpense) ? (
        <Grid item>
          <Button
            variant="contained"
            disabled={!total}
            onClick={() => this.handleSubmitExpense(onDeleteExpense)}
          >
            Delete
          </Button>
        </Grid>
      ) : null;

      reviewButtonsGridItem = (
        <Grid item style={{marginBottom:theme.spacing(3)}}>
          <Grid container spacing={2}>
            {submitButtonGridItem}
            {deleteButtonGridItem}
          </Grid>
        </Grid>
      );
    }

    const additionalButtonGridItems = [];
    if (additionalButtons) {
      additionalButtons.forEach((button, i) => {
        additionalButtonGridItems.push(
          <Grid item key={`ab_${i}`}>
            {button}
          </Grid>
        );
      });
    }

    let approvalButtonsGridItem;
    if (onApproveExpense || onDenyExpense) {
      const approveButtonGridItem = (onApproveExpense) ? (
        <Grid item>
          <Button
            variant="contained"
            style={{
              backgroundColor:green[500],
              color:"white",
            }}
            onClick={() => this.handleSubmitExpense(onApproveExpense)}
          >
            Approve
          </Button>
        </Grid>
      ) : null;

      const denyButtonGridItem = (onDenyExpense) ? (
        <Grid item>
          <Button
            variant="contained"
            style={{
              backgroundColor:red[500],
              color:"white",
            }}
            onClick={() => this.handleSubmitExpense(onDenyExpense)}
          >
            Deny
          </Button>
        </Grid>
      ) : null;

      approvalButtonsGridItem = (
        <Grid item style={{marginBottom:theme.spacing(3)}}>
          <Grid container spacing={2}>
            {approveButtonGridItem}
            {denyButtonGridItem}
          </Grid>
        </Grid>
      );
    }

    const dataGridEmployeeControl = GetEmployeeControl(this.context,
      employeeId => this.handleLoadSingleExpense(false, employeeId), 
      allowEmployeeChange && User && User.Employees && User.Employees.length > 1, true, 
      { backgroundColor: getInputBackground(readOnly)},
    );

    const expenseDataGrid = (
      <Grid container direction="column" spacing={2} style={{
        marginTop:theme.spacing(1),
        // marginBottom:theme.spacing(3),
        position:"relative",
      }}>
        {(IsMobile()) ? (
          <Grid item>
            {dataGridEmployeeControl}
          </Grid>
        ) : null}
        <Grid item>
          <div style={{display:"flex"}}>
            {(!IsMobile()) ? (
              <div style={{
                marginRight:theme.spacing(1),
                maxWidth:(IsMobile()) ? 100 : 300,
              }}>
                {dataGridEmployeeControl}
              </div>
            ) : null}
            <TextField
              label="Expense date"
              variant="outlined"
              type="date"
              disabled={readOnly}
              inputProps={{
                max:"9999-12-31",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor:getInputBackground(readOnly) }}
              onChange={this.handleUpdateExpenseProperty("ReceiptDate")}
              value={Expense && Expense.Expense.ReceiptDate}
            />
            <div style={{flexGrow:1,marginLeft:theme.spacing(1)}}>
              {showPageViewDialogContent}
            </div>
          </div>
        </Grid>
        <Grid item>
          {dataGrid}
        </Grid>
        {reviewButtonsGridItem}
        {approvalButtonsGridItem}
        {additionalButtonGridItems}
        {overlay}
      </Grid>
    );

    const innerContent = (!showPagesInline) ? expenseDataGrid : (
      <Grid container spacing={5} style={{height:"100%",marginTop:0}}>
        <Grid item xs={5} className={classes.scrollableGridItem}
          ref={instance => this.ExpenseDataGridRef = instance}>
          {expenseDataGrid}
        </Grid>
        <Grid item xs={7} className={classes.scrollableGridItem}
          ref={instance => this.PageViewGridRef = instance}>
          {pageViewer}
        </Grid>
      </Grid>
    );

    const expenseContent = (Expense)
      ? (
        <Container 
          maxWidth={(Expense && Expense.PageCount) ? "lg" : "sm"}
          style={{padding:0,height:"100%"}}
          ref={instance => { if (!showPagesInline) { this.ExpenseDataGridRef = instance; }}}
        >
          {(!showPagesInline) ? pageViewer : null}
          {innerContent}
        </Container>
      ) : (ShowPageProcessingIndicator)
        ? (
          <ProgressIndicator
            constrained
            noBackground
            showImmediately
            linear
            containerStyle={{
              justifyContent:"flex-start",
              paddingTop:120,
            }}
            preContent={(
              <div style={{
                fontSize:18,
                fontWeight:500,
                marginBottom:theme.spacing(2),
              }}>
                Receipt analysis in progress...
              </div>
            )}
          />
        )
        : (
          <div style={{
            display:"flex",
            flexDirection:"column",
            height:"100%",
          }}>
            <div style={{
              marginTop:8,
              width:(IsMobile()) ? "100%" : "25%",
            }}>
              {
                GetEmployeeControl(this.context,
                  employeeId => this.handleLoadSingleExpense(false, employeeId),
                  true,
                )
              }
            </div>
            <div style={{
              flexGrow:1,
              display:"flex",
              flexDirection:"column",
              width:"100%",
              height:"70%",
              textAlign:"center",
              justifyContent:"center",
              alignItems:"center",
            }}>
              <Typography variant="h5" color="secondary" style={{fontWeight:500,marginBottom:theme.spacing(1)}}>
                Congratulations!
              </Typography>
              <Typography variant="h6" color="primary">
                All expenses have been handled.
              </Typography>
            </div>
          </div>
        );

    const content = (IsReady) ? expenseContent : null;

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title={dialogTitle}
        apiError={ApiError}
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
        funcForAutoRefresh={this.handleAutoRefresh}
        setScreenContentRef={this.handleSetScreenContentRef}
      />
    );
  }
}

ExpenseViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  onLoadSingleExpense: PropTypes.func,
  onApproveExpense: PropTypes.func,
  onDenyExpense: PropTypes.func,
  onSubmitExpense: PropTypes.func,
  onDeleteExpense: PropTypes.func,
  onProcessDeletedExpense: PropTypes.func,
  readOnly: PropTypes.bool,
  dialogTitle: PropTypes.string,
  additionalButtons: PropTypes.array,
  returnContentOnly: PropTypes.bool,
  screenContentRef: PropTypes.object,
  allowEmployeeChange: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ExpenseViewer);

import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import UiCore from '../Components/UiCore';
import CaptureCore from '../Components/CaptureCore';
import ExpenseViewer from '../Components/ExpenseViewer';

import API, {
  GetExpensePageUploadsPathForApi,
  GetExpenseStagePathForApi,
  GetExpensesPathForApi,
} from '../Util/api';
import {
  GetExpenseReviewPath,
} from '../Util/PathHelper';
import {
  GetEmployeeControl,
} from '../Util/Employee';
import { GlobalContext } from '../Context/Global.context';
import MultiUseDialog from '../Components/MultiUseDialog';

import { IsMobile } from '../Util/MobileDetector';

import green from '@material-ui/core/colors/green';

const styles = theme => ({
});

class ExpenseCapture extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.initialState = {
        PagesCaptured: [],
        ShowExpenseViewerForManualEntry: false,
        ReservationParams: this.getNewReservationParams(),
        ShowProgressIndicatorImmediately: false,
      }

    this.state = {
      ...this.initialState,
      IsReady: false,
      ShowMoveForwardDialog: false,
      ShowProgressIndicatorImmediately: true,
    }
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  handleSetShowMoveForwardDialog = ShowMoveForwardDialog => {
    this.setState({ShowMoveForwardDialog});
  }

  componentDidMount() {
    this.context.GetUser(true)
      .then(user => {
        this.setState({
          IsReady: true,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  componentDidUpdate() {
  }

  getNewReservationParams = () => {
    return {
      pageId: 0,
    };
  }

  handleCompleteUpload = reservation => {
    const ReservationParams = {
      ...this.state.ReservationParams,
      expenseId: (reservation) ? reservation.ExpenseID : "",
      pageId: (reservation) ? 1 + reservation.PageID : 0,
    };
    const PagesCaptured = [
      ...this.state.PagesCaptured,
      {
        ExpenseID: reservation.ExpenseID,
        PageID: reservation.PageID,
      },
    ];
    this.setState({ 
      ReservationParams,
      PagesCaptured,
    });
  }

  handleShowExpenseViewerForManualEntry = ShowExpenseViewerForManualEntry => {
    this.setState({ShowExpenseViewerForManualEntry});
  }

  handleFinalizeExpenseUploads = () => {
    if (!this.state.PagesCaptured.length) {
      return;
    }
    this.setState({ShowProgressIndicatorImmediately: true});

    const expenseId = this.state.PagesCaptured[0].ExpenseID;

    API.post(GetExpenseStagePathForApi(this.context.EmployeeID, expenseId, "pageProcessing"))
      .then(resp => {
        this.handleSetShowMoveForwardDialog(true);
        this.handleReset();
      })
      .catch(this.handleApiError);
  }

  handleSubmitExpense = expense => {
    return API.post(GetExpensesPathForApi(this.context.EmployeeID), {Expenses:[expense]})
      .then(() => {
        this.handleReset();
        this.handleShowExpenseViewerForManualEntry(false);
      });
  }

  handleReset = () => {
    this.setState({ ...this.initialState });
  }

  render() {
    const {
      IsReady,
      PagesCaptured,
      ReservationParams,
      ApiError,
      Alert,
      ShowMoveForwardDialog,
      ShowExpenseViewerForManualEntry,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      // classes,
      theme,
      history,
      location,
    } = this.props;
    const {
      EmployeeID,
    } = this.context;

    const startOverGridItem = (PagesCaptured && PagesCaptured.length)
      ? (
        <Grid item>
          <Button
            variant="contained"
            disabled={!PagesCaptured || !PagesCaptured.length}
            onClick={() => this.handleReset()}
          >
            Start over
          </Button>
        </Grid>
      ) : null;
    const finishItemsDisabled = !PagesCaptured || !PagesCaptured.length;
    const pagesCapturedContent = (!finishItemsDisabled) ? (
      <Grid container spacing={2} direction={(IsMobile()) ? "column" : undefined} style={{alignItems:"center"}}>
        <Grid item>Pages Captured: {PagesCaptured && PagesCaptured.length}</Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{
              backgroundColor: green[500],
              color: "white",
            }}
            onClick={() => this.handleFinalizeExpenseUploads()}
          >
            Finish
          </Button>
        </Grid>
        {startOverGridItem}
      </Grid>
    ) : null;

    const enterManuallyButton = (!PagesCaptured || !PagesCaptured.length) ? (
      <React.Fragment>
        <Typography variant="body1" style={{marginBottom:12}}>
          or
        </Typography>
        <Button
          variant="contained"
          onClick={() => this.handleShowExpenseViewerForManualEntry(true)}
        >
          Enter Manually
        </Button>
      </React.Fragment>
    ) : null;

    const moveForwardDialog = (ShowMoveForwardDialog) ? (
      <MultiUseDialog Details={{
        Open: true,
        // ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
        Title:"Capture another receipt?",
        IsConfirmation:true,
        CancelCallback:() => this.handleSetShowMoveForwardDialog(false),
        CloseCallback:() => this.handleSetShowMoveForwardDialog(false),
        CancelLabel: "Stay here",
        ConfirmLabel: "Move forward",
        ConfirmButtonStyle: {
          marginTop:theme.spacing(3),
        },
        ConfirmCallback:() => this.props.history.push(GetExpenseReviewPath()),
        DialogActionsStyle: {
          flexDirection:"column",
        },
        DialogContentStyle: {
          padding:0,
        },
      }} />  
    ) : null;

    const nextPageNumber = (PagesCaptured && PagesCaptured.length && (1 + PagesCaptured.length)) || 0;
    let content;
    if (IsReady) {
      if (ShowExpenseViewerForManualEntry) {
        const additionalButtons = [(
          <Button variant="contained"
            onClick={() => this.handleShowExpenseViewerForManualEntry(false)}
            style={{marginTop:theme.spacing(1)}}
          >
            Go back
          </Button>
        )];
        content = (
          <ExpenseViewer
            dialogTitle="Expense Entry"
            onSubmitExpense={this.handleSubmitExpense}
            additionalButtons={additionalButtons}
            returnContentOnly
          />
        );
      } else {
        content = (
          <div style={{
            paddingBottom: theme.spacing(4),
            height:"100%",
            display:"flex",
            flexDirection:"column",
          }}>
            {moveForwardDialog}
            <div style={{
              marginTop:8,
              marginBottom:24,
              width:(IsMobile()) ? "100%" : "25%",
            }}>
              {GetEmployeeControl(this.context, null, true)}
            </div>
            <div style={{
              flexGrow: (!IsMobile()) ? 1 : undefined,
              border:(!IsMobile()) ? "2px dashed #888" : undefined,
              borderRadius: 16,
            }}>
              <CaptureCore
                history={history}
                location={location}
                singleItemName={
                  (IsMobile())
                    ? `${(nextPageNumber) ? "Page " + nextPageNumber : "Receipt Page"}`
                    : `${(nextPageNumber) ? "Page " + nextPageNumber : "Receipt"}`
                }
                additionalContent={enterManuallyButton}
                singleFile
                skipCompleteAlert
                onComplete={this.handleCompleteUpload}
                // forceHideInstructionContent
                reservationUri={GetExpensePageUploadsPathForApi(EmployeeID)}
                reservationParams={ReservationParams}
                // onSetBeginFileUploadFunc={onSetBeginFileUploadFunc}
                // onClose={() => this.handleRefresh()}
                onApiError={this.handleApiError}
                onAlert={this.handleAlert}>
              </CaptureCore>
            </div>
            <div style={{marginTop:theme.spacing(4)}}>
              {pagesCapturedContent}
            </div>
          </div>
        );
      }
    }

    return (
      <UiCore title={(ShowExpenseViewerForManualEntry) ? "Expense Entry" : "Receipt Capture"}
        apiError={ApiError}
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

ExpenseCapture.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ExpenseCapture);

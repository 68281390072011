import React, {Component} from 'react';

import ExpenseViewer from '../Components/ExpenseViewer';

import API, {
  GetExpenseApprovalsPathForApi,
  GetExpenseApprovalPathForApi,
} from '../Util/api';
import { GlobalContext } from '../Context/Global.context';

class ExpenseApproval extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  handleLoadSingleExpense = employeeId => {
    const params = {
      pageSize: 1,
    }
    return API.get(GetExpenseApprovalsPathForApi(employeeId || this.context.EmployeeID), { params });
  }

  handleSubmitExpense = (expense, approvalResult) => {
    expense.ApprovalResult = approvalResult;
    return API.post(GetExpenseApprovalPathForApi(this.context.EmployeeID, expense.ID), expense);
  }

  handleApproveExpense = expense => {
    return this.handleSubmitExpense(expense, "Approved");
  }

  handleDenyExpense = expense => {
    return this.handleSubmitExpense(expense, "Denied");
  }

  render() {
    return (
      <ExpenseViewer
        dialogTitle="Expense Approval"
        readOnly
        allowEmployeeChange
        onLoadSingleExpense={this.handleLoadSingleExpense}
        onApproveExpense={this.handleApproveExpense}
        onDenyExpense={this.handleDenyExpense}
      />
    );
  }
}

export default ExpenseApproval;

import React, {Component} from 'react';

// import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import UiCore from '../Components/UiCore';
import ExpenseViewDialog from '../Components/ExpenseViewDialog';
import EnhancedTableHead from '../Components/EnhancedTableHead';

import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';

import API, {
  GetExpensesPathForApi,
} from '../Util/api';
import {
  GetTotal,
} from '../Util/Expense';
import {
  GetEmployeeControl,
} from '../Util/Employee';

import { GlobalContext } from '../Context/Global.context';
import { CurrencyWithSeparators } from '../Util/NumberFormatting';
import { IsMobile } from '../Util/MobileDetector';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import dateformat from 'dateformat';

const styles = theme => ({
  table: {
    backgroundColor:theme.palette.background.paper,
    padding:theme.spacing(2),
    paddingTop:0,
    borderRadius:16,
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(3),
  },
  tableCell: {
    fontSize: (IsMobile()) ? 16 : 20,
    backgroundColor:theme.palette.background.paper,
  },
});

class ExpenseHistory extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.initialState = {
        Expenses: [],
        ExpensesLoaded: false,
        PrevCursors: [],
        CurCursor: "",
        NextCursor: null,
        ShowExpenseViewDialog: false,
        ExpenseForDialog: null,
        ShowProgressIndicatorImmediately: false,
      }

    this.state = {
      ...this.initialState,
      IsReady: false,
      ShowProgressIndicatorImmediately: true,
    }
  }

  handleApiError = ApiError => {
    this.setState({ApiError});
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  componentDidMount() {
    this.context.GetUser(true)
      .then(user => {
        this.setState({
          IsReady: true,
          ShowProgressIndicatorImmediately: false,
        });
        this.loadExpenses();
      })
      .catch(this.handleApiError);
  }

  componentDidUpdate() {
  }

  handleEmployeeChange = employeeId => {
    this.setState({
      ...this.initialState,
    })
    this.loadExpenses(null, employeeId)
  }

  loadExpenses = (cursor, employeeId) => {
    let params = {};
    if (cursor) {
      params.cursor = cursor;
    }
    this.setState({
      ShowProgressIndicatorImmediately: true,
      ExpensesLoaded: false,
    });
    API.get(GetExpensesPathForApi(employeeId || this.context.EmployeeID), { params })
      .then(resp => {
        const expenseList = resp.data;
        this.setState({
          Expenses: expenseList.Expenses,
          CurCursor: cursor || "",
          NextCursor: expenseList.Cursor || null,
          ShowProgressIndicatorImmediately: false,
          ExpensesLoaded: true,
        });
      })
      .catch(this.handleApiError);
  }

  handleLoadPrev = () => {
    const PrevCursors = [...this.state.PrevCursors];
    const cursor = PrevCursors.pop();
    this.setState({PrevCursors});
    this.loadExpenses(cursor)
  }

  handleLoadNext = () => {
    const PrevCursors = [...this.state.PrevCursors];
    PrevCursors.push(this.state.CurCursor);
    this.setState({PrevCursors});
    this.loadExpenses(this.state.NextCursor);
  }

  handleShowExpenseViewDialog = (ShowExpenseViewDialog, ExpenseForDialog, reloadResults) => {
    this.setState({
      ShowExpenseViewDialog,
      ExpenseForDialog,
    });
    if (reloadResults) {
      this.loadExpenses();
    }
  }

  render() {
    const {
      IsReady,
      Expenses,
      ExpensesLoaded,
      CurCursor,
      PrevCursors,
      NextCursor,
      ApiError,
      Alert,
      ShowExpenseViewDialog,
      ExpenseForDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
    } = this.props;
    
    let expenseRows = [];
    if (Expenses && Expenses.length) {
      Expenses.forEach(e => {
        const total = GetTotal(e.Expense);
        let stateLabel = "";
        let stateStyle = {};
        switch (e.Stage) {
        case "Rejected":
          stateLabel = "Rejected";
          stateStyle.color = red[500];
          break;
        case "Complete":
          if (e.ApprovalResult) {
            switch (e.ApprovalResult) {
            case "Approved":
              stateLabel = "Approved";
              stateStyle.color = green[500];
              break;
            case "Denied":
              stateLabel = "Denied";
              stateStyle.color = red[500];
              break;
            default:
              break;
            }
          } else if (this.context.User && this.context.User.IsDemoCompany) {
            stateLabel = "Complete";
          } else {
            stateLabel = "Submitted";
          }
          break;
        case "Denied":
          stateLabel = "Denied";
          stateStyle.color = red[500];
          break;
        case "Deleted":
          stateLabel = "Deleted";
          break;
        case "PageProcessing":
          stateLabel = "Analysis in progress";
          break;
        case "Review":
          stateLabel = "Ready for review";
          break;
        case "Approval":
          stateLabel = "Awaiting approval";
          break;
        case "Creation":
          stateLabel = "Submission in progress";
          break;
        default:
          break;
        }
        const stateCell = (true) ? (
          <TableCell className={classes.tableCell} style={stateStyle}>
            {stateLabel}
          </TableCell>
        ) : null;
        const pagesCell = (!IsMobile()) ? (
          <TableCell className={classes.tableCell} style={{textWrap:"nowrap"}}>
            {`${e.PageCount || ""}${(e.PageCount) ? ((e.PageCount > 1) ? " pages" : " page") : ""}`}
          </TableCell>
        ) : null;
        expenseRows.push(
          <TableRow key={`row_${e.ID}`} hover
            onClick={() => this.handleShowExpenseViewDialog(true, e)}
            style={{cursor: "pointer"}}
          >
            <TableCell className={classes.tableCell} padding="none">
              {dateformat(e.CreatedOn, (IsMobile()) ? "m/d" : "m/d/yy")}
            </TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:500,fontSize:(IsMobile()) ? 12 : undefined}}>
              {`${e.Expense.VendorName}`}
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {`${(total) ? CurrencyWithSeparators(total) : ""}`}
            </TableCell>
            {stateCell}
            {pagesCell}
          </TableRow>
          
        );
      });
    }

    const collectionFields = [
      "Date",
      "Vendor",
      "Total",
      "State",
      "Pages",
    ];

    const headCells = [
      { id: collectionFields[0], numeric: false, label: collectionFields[0] },
      { id: collectionFields[1], numeric: false, label: collectionFields[1] },
      { id: collectionFields[2], numeric: true, label: collectionFields[2] },
    ];
    if (!IsMobile()) {
      headCells.push(
        { id: collectionFields[3], numeric: false, label: collectionFields[3] },
        { id: collectionFields[4], numeric: false, label: collectionFields[4] }, 
      );
    }

    const employeeControl = GetEmployeeControl(this.context, this.handleEmployeeChange, true);

    const tableOrNoHistory = (expenseRows && expenseRows.length) ? (
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
      >
        <EnhancedTableHead
          className={classes.tableHead}
          headCells={headCells}
          sortDescending={false}
          collectionFields={collectionFields}
          showSelectionColumn={false}
        />
        <TableBody>
          {expenseRows}
        </TableBody>
      </Table>
    ) : (ExpensesLoaded && !CurCursor) ? (
      <div style={{
        height:"100%",
        display:"flex",
        flexDirection:"column",
      }}>
        <div style={{
          marginTop:8,
          width:IsMobile() ? "100%" : "25%",
        }}>
          {employeeControl}
        </div>
        <div style={{
          flexGrow:1,
          display:"flex",
          flexDirection:"column",
          width:"100%",
          height:"70%",
          textAlign:"center",
          justifyContent:"center",
          alignItems:"center",
        }}>
          <Typography variant="h6" color="primary">
            You don't have an expense history.
          </Typography>
        </div>
      </div>
    ) : null;

    const expenseViewDialog = (ShowExpenseViewDialog) ? (
      <ExpenseViewDialog
        expense={ExpenseForDialog}
        onClose={reloadExpenses => this.handleShowExpenseViewDialog(false, null, reloadExpenses)}
      />
    ) : null;

    const navigation = (CurCursor || (expenseRows && expenseRows.length)) ? (
      <div style={{display:"flex",marginTop:theme.spacing(1)}}>
        {employeeControl}
        <IconButton onClick={() => this.handleLoadPrev()} disabled={!PrevCursors.length}>
          <PrevIcon />
        </IconButton>
        <IconButton onClick={() => this.handleLoadNext()} disabled={NextCursor === null}>
          <NextIcon />
        </IconButton>
      </div>
    ) : null;

    const content = (IsReady)
      ? (
        <Container maxWidth="lg" style={{height:"100%",padding:IsMobile() ? 0 : undefined}}>
          {expenseViewDialog}
          {navigation}
          {tableOrNoHistory}
          </Container>
      ) : null;

    return (
      <UiCore title="Expense History"
        apiError={ApiError}
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

ExpenseHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ExpenseHistory);
